import React from "react";
import "./PageTitleStyle.css";

interface PageTitleProps {
  title?: string;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}
const PageTitle = (props: PageTitleProps) => {
  const { title, variant } = props;

  switch (variant) {
    case "h1":
      return (
        <div className="title-wrapper">
          <h1>{title}</h1>
        </div>
      );
    case "h2":
      return (
        <div className="title-wrapper">
          <h2>{title}</h2>
        </div>
      );
    case "h3":
      return (
        <div className="title-wrapper">
          <h3>{title}</h3>
        </div>
      );

    case "h4":
      return (
        <div className="title-wrapper">
          <h4>{title}</h4>
        </div>
      );

    case "h5":
      return (
        <div className="title-wrapper">
          <h5>{title}</h5>
        </div>
      );
    case "h6":
      return (
        <div className="title-wrapper">
          <h6>{title}</h6>
        </div>
      );
    default:
      return (
        <div className="title-wrapper">
          <h1>{title}</h1>
        </div>
      );
  }
};
export default PageTitle;
