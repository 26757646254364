import React from "react";
import ContentWrapper from "../master/ContentWrapper";
import PageTitle from "../master/PageTittle";
import kissgoBottomImage from "../../assets/community/kissgo.jpg";
import {Link} from "gatsby";
import SideBar from "../master/SideBar";
import {community} from "../const";

const questionList = [
    {description: '1. Parents arrive on or after 3.00pm.', key: 0},
    {
        description: '2. The Family name card issued by the College is to be displayed behind the windscreen on the\n' +
            'passenger side as you approach the pick-up zone.', key: 1
    },
    {
        description: '3. A staff member will take the students to the pick-up zone and will supervise the students at all\n' +
            'times (Students will be lined up inside the School, just inside the top exit gate).', key: 2
    },
    {
        description: '4. Another staff member will call out the name of students as the car approaches. Staff will \n' +
            'communicate via a megaphone/walkie talkie.', key: 3
    },
    {description: '5. Students will then come forward to get in the car.', key: 4},
    {
        description: '6. To assist traffic flow, drivers must stay in the car.\n' +
            '(Please teach your children to recognise your car and train them to enter the vehicle quickly and\n' +
            'safely)', key: 5
    },

    {
        description: '7. Ensure your child enters the vehicle on the kerb side and has their restraint securely fastened\n' +
            'before moving off.', key: 6
    },


    {
        description: '8. Cars leave pick-up zone in the order of arrival, ie. no car leaves before the car in first position\n' +
            'exits the Kiss & GO zone.', key: 7
    },

    {
        description: '9. If your child is not at the Kiss & GO zone within 1 minute of when you arrive, you are required to circle around the block and re-join the line. This system works extremely well when cars keep moving, remembering they are only permitted to ‘stop’ for 1 minute.',
        key: 8
    },


    {
        description: '10. The College recommends you go around the block through Margaret Street. See diagram below.',
        key: 9
    },
    {
        description: '11. Please go round the block by turning right into Margaret Street, then right onto Canonbury Grove and then right on either Beach or Marrickville Road to join the queue again on Wardell Road.',
        key: 10
    },

    {
        description: '12. Staff will supervise students up to 3.20pm.',
        key: 11
    },
];
const KissGoContent = () => (
    <div className="container">
        <ContentWrapper sidebar={true} narrow={true}>
            <div className={"content"}>
                <PageTitle title="NEW KISS & GO ZONE FOR AFTERNOON PICK-UP"/>


                <p>
                    Due to the streets surrounding the School, having minimal parking, we are introducing a procedure,
                    that involves a Kiss & GO Zone directly out the front of the School. The drop off and pick up
                    procedures have been designed for your convenience and for your child’s safety and aim to minimise
                    traffic frustration for families and the local community when used properly by all parents. These
                    procedures will commence from the first day back in Term 2, Wednesday 26th April 2023.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Parents need to register</span> for afternoon pick up from the
                    Kiss & GO zone, a sign will be issued to
                    each registered family with the surname & student first names that will need to be displayed on your
                    car’s front dashboard. Please contact the School by email <a href="mail:info@stmarouns.nsw.edu.au">
                    info@stmarouns.nsw.edu.au
                </a>, or phone 9559-
                    2434 by Friday 14 April to register and a sign will be organised for collection from Monday 17 April
                    from the administration office or alternatively it will be given to the eldest child on the first
                    day back of Term 2, Wednesday 26 April.
                </p>

                <ul>
                    <li> The Kiss and Go Zone operates under
                        “No Parking” traffic rules, therefore no parking between 8am to 9.30am and 2.30pm – 4pm on
                        school
                        days.
                    </li>
                    <li> Stopping for more than 1 minute is not allowed.</li>
                    <li> Penalties for not complying with School Zone parking are levied by Inner west Council and
                        RMS, rangers will be active in the area during these times.
                    </li>
                </ul>

                <PageTitle title="AFTERNOON PROCEDURES:" variant="h3"/>

                <ol>
                    {questionList.map(question => {
                        return (
                            <li key={question.key}>{question.description}</li>
                        );
                    })}
                </ol>


                <h2>
                    DO NOT:
                </h2>


                <ul>
                    <li>Do not obstruct traffic flow by waiting for a spot. Instead go around the block.</li>
                    <li>Do not double park or overtake.</li>
                    <li>Do not undertake any U-turns in close proximity to the College.</li>
                    <li>Do not be inconsiderate of other parents or risk childrens’ safety.
                    </li>
                </ul>

                <p>
                    Staff members have been instructed to remind drivers to comply with the road rules and these
                    procedures. Your respect for these staff members is expected and appreciated.
                </p>

                <p>
                    Please note, Kiss & Ride is a privilege not a right. If you are a repeat offender of these rules
                    your children may lose the right to join the Kiss & Go zone after School.
                </p>

                <p>
                    Note: The College is waiting on formal approval to extend the Kiss & GO zone up to the Gilbert Barry
                    Reserve, this will ensure the smooth operating of this zone.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Diagram:</span> Drive around the block by turning into Margaret Street, if your child has not presented
                    after 1 minute or if there is no space in the queue.
                </p>


            </div>
            {/* <SideBar items={community} title="Our Community" /> */}


        </ContentWrapper>

        <img
            src={kissgoBottomImage}
            className="img-fluid justify-content-center "
            height={600}
            alt="..."
        />
    </div>
);

export default KissGoContent;
