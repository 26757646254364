import React from "react";
import "./BannerImageStyle.css";

interface PageTitleProps {
  imagePath?: string;
  altText?: string;
}
const PageTitle = (props: PageTitleProps) => {
  const { imagePath, altText } = props;
  return (
    <div className="banner-image-wrapper">
      <img src={imagePath} alt={altText} />
    </div>
  );
};
export default PageTitle;
