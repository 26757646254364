import * as React from "react";
import Layout from "../components/layout";
import KissGoContent from "../components/community/KissGoContent";
import BannerImage from "../components/master/BannerImage";
import heroImage from "./../assets/about/vision-mssion-values-hero.jpg";
const KissandGoPage = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="..." />
      <KissGoContent />
    </Layout>
  );
};

export default KissandGoPage;
