import React from "react";
import "./ContentWrapperStyle.css";

interface ContentWrapperProps {
  sidebar?: boolean;
  narrow?: boolean;
  children?: any;
}
const ContentWrapper = (props: ContentWrapperProps) => {
  const { children, sidebar, narrow } = props;
  return (
    <div
      className={`content-wrapper ${sidebar && "with-sidebar"} ${
        narrow && "narrow-content"
      }`}
    >
      {children}
    </div>
  );
};
export default ContentWrapper;
